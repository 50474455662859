import {Controller} from "@hotwired/stimulus"
import {useDebounce} from "stimulus-use"

// Connects to data-controller="form-select"
export default class extends Controller {
  static debounces = ['submit_with_debounce']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  submit() {
    this.element.requestSubmit()
  }

  submit_with_debounce(){
    console.log('submit')
    this.element.requestSubmit()
  }
}
